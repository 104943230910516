import AboutPagePicture from '../../constants/AboutPagePicture.JPG';
import { colors } from '../../constants/colors';
const About = () => {
    return (
      <div
        className="flex"
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          fontFamily: "LondrinaSolid",
        }}
      >
        {/* Left Side: Image */}
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: '20px'
          }}
        >
          <img
            src={AboutPagePicture} // Replace with the actual path to your image
            alt="Isabella Martin"
            style={{
              width: '60%', // Adjust size as needed
              height: 'auto',
            }}
          />
        </div>
  
        {/* Right Side: Title and Text */}
        <div
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '20px',
          }}
        >
          {/* Title Box */}
          <div
            style={{
              backgroundColor: colors.purple,
              color: 'white',
              padding: '10px 15px',
              marginBottom: '20px',
              width: '95%'
            }}
          >
            <h1 style={{ margin: 0 }}>a little about me.</h1>
          </div>
  
          {/* Paragraphs */}
          <div>
            <p style={{ marginBottom: '15px' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce auctor lorem eget tellus euismod, in pulvinar nunc hendrerit.
            </p>
            <p>
              Vivamus at metus ut nisl laoreet dictum. Curabitur euismod dolor nec eros laoreet, id tincidunt metus tincidunt. Donec eget ligula libero.
            </p>
          </div>
        </div>
      </div>
    );
  };
  
  export default About;
  