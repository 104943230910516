import React, { useState } from 'react';
import { colors } from '../../constants/colors';

const ContactForm = () => {
  // State to store input values
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    subject: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log('Form submitted:', formData);

    // Perform the desired action here
    alert('Form submitted! Check the console for details.');
  };

  return (
    <form 
      onSubmit={handleSubmit} 
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        fontFamily: 'LondrinaSolid',
        marginTop: '50px'
      }}
    >
      <div 
        style={{ 
          display: 'flex', 
          gap: '10px',
        }}
      >
        <input
          type="text"
          name="firstName"
          placeholder="first name"
          value={formData.firstName}
          onChange={handleChange}
          style={{ flex: 1, padding: '10px', fontSize: '16px', fontFamily: 'LondrinaSolid'}}
        />
        <input
          type="text"
          name="lastName"
          placeholder="last name"
          value={formData.lastName}
          onChange={handleChange}
          style={{ flex: 1, padding: '10px', fontSize: '16px', fontFamily: 'LondrinaSolid'}}
        />
      </div>
      <input
        type="email"
        name="email"
        placeholder="email"
        value={formData.email}
        onChange={handleChange}
        style={{ padding: '10px', fontSize: '16px', fontFamily: 'LondrinaSolid'}}
      />
      <input
        type="text"
        name="subject"
        placeholder="subject"
        value={formData.subject}
        onChange={handleChange}
        style={{ padding: '10px', fontSize: '16px', fontFamily: 'LondrinaSolid' }}
      />
      <textarea
        name="message"
        placeholder="message"
        value={formData.message}
        onChange={handleChange}
        style={{ padding: '10px', fontSize: '16px', height: '100px', fontFamily: 'LondrinaSolid'}}
      ></textarea>
      <button
        type="submit"
        style={{
          padding: '10px',
          backgroundColor: colors.blue,
          fontFamily: 'LondrinaSolid',
          color: 'white',
          fontSize: '16px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        SUBMIT
      </button>
    </form>
  );
};

export default ContactForm;
