import ProjectCard from "./ProjectCard";

const Projects = () => {

    const paragraphOne = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
    Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. 
    Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. 
    Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa.
    Maecenas et iaculis velit, nec malesuada magna. 
    Aenean consequat tempor luctus. In erat lorem, venenatis at nisl a, facilisis consequat justo. 
    Vestibulum congue magna in nisl volutpat molestie vitae sit amet enim. 
    Donec varius tortor quis lacus tincidunt, id posuere eros pulvinar. 
  `;

    const paragraphTwo = `
    Morbi felis ex, porttitor vestibulum mi quis, venenatis sodales arcu. 
    Pellentesque orci nibh, suscipit in elementum pellentesque, vehicula quis ante. 
    Duis quis mi leo. Ut nec nisi non leo consectetur blandit. 
    Maecenas et iaculis velit, nec malesuada magna. 
    Aenean consequat tempor luctus. In erat lorem, venenatis at nisl a, facilisis consequat justo. 
    Vestibulum congue magna in nisl volutpat molestie vitae sit amet enim. 
    Donec varius tortor quis lacus tincidunt, id posuere eros pulvinar. 
    Proin placerat neque at ante elementum dapibus. Integer porta mauris non sagittis aliquet. 
    Proin non arcu mattis, dignissim dolor vulputate, porta ex. 
    Phasellus faucibus tellus sit amet nisi malesuada, vel suscipit magna malesuada. 
    Proin sollicitudin velit id imperdiet pulvinar. Maecenas sagittis sed erat ac lacinia.
    `;
        return(
        <div className="flex row" style={{ fontFamily: "LondrinaSolid", justifyContent: '20px', justifyItems: '20px'}}>
            {/* <div style={{flex: 1, display: 'flex'}}>
                <ProjectCard text={paragraphOne}/>
            </div>
            <div className="flex 1">
                <ProjectCard text={paragraphTwo}/>
            </div> */}
        </div>
    )
}

export default Projects;