import React from "react";
import HomeContent from "./HomeContent";

const Home = () => {
    return(
        <div>
            <HomeContent/>
        </div>
    )
}

export default Home;