import { colors } from "../../constants/colors";
import ContactForm from "./ContactForm";

const Contact = () => {
    return (
        <div 
          className="flex" 
          style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', fontFamily: "LondrinaSolid"}}
        >
          <div 
            style={{ 
                flex: 1, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                marginRight: '20px'
            }}
          >
            <h2 style={{fontSize: '10vw', margin: '20px', color: colors.blue}}>Let's get in touch? </h2>
          </div>
          <div 
            style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <ContactForm/>
          </div>
        </div>
      );
    };

export default Contact;