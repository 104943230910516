import React, {useState} from 'react';
import Button from '@mui/material/Button';
import { colors } from '../../constants/colors';
import Confetti from 'react-confetti-boom';

const HomeContent = () => {
    const [showConfetti, setShowConfetti] = useState(false);
    const handleButtonClick = () => {
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 5000); 
      };

    return (
        <div style={{ fontFamily: "LondrinaSolid", margin: '20px'}}>
        <h2 style={{fontSize: '9vw', marginBottom: '0px'}}>HI</h2>
        <h2 style={{fontSize: '10vw', marginBottom: '10px', marginTop: '0px'}}>I'M ISABELLA</h2>
        <Button className="welcome-button" 
            onClick={handleButtonClick}
            disabled={showConfetti}
            style={{
                fontFamily: 'LondrinaSolid', 
                background: colors.blue, 
                color: colors.white,
                outline: 'none',
                borderRadius: '0px'
                }}
            >welcome to my page</Button>
            {showConfetti && (
        <Confetti
          mode="boom"
          particleCount={100}
          x={0.15}
          colors={['#ff577f', '#ff884b']}
        />
      )}
        </div>
    );
};

export default HomeContent;
