import React from 'react';
import { IconButton, Box } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '10px 20px',
        backgroundColor: 'black',
        color: 'white',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      }}
    >
    <a href="https://www.linkedin.com/in/isabella-a-martin/" target="_blank" rel="noopener noreferrer">
      <IconButton sx={{ color: 'white' }}>
        <LinkedInIcon />
      </IconButton>
    </a>
    <a href="https://github.com/isabellaamartinn9?tab=projects" target="_blank" rel="noopener noreferrer">
      <IconButton sx={{ color: 'white' }}>
        <GitHubIcon />
      </IconButton>
    </a>
    <a href="mailto:isabellamartin@gatech.edu">
      <IconButton sx={{ color: 'white' }}>
        <EmailIcon />
      </IconButton>
    </a>
    </Box>
  );
};

export default Footer;
