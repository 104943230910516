import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { colors } from '../../constants/colors';

const Resume = () => {
  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '20px',
    marginBottom: '40px', // Space between rows
    width: '100%',
  };

  const educTextStyle = {
    fontFamily: 'LondrinaSolid',
    fontSize: '2rem',
    textAlign: 'right',
    color: colors.pink,
    flex: '1', // Reserve space for the text
  };

  const experienceTextStyle = {
    fontFamily: 'LondrinaSolid',
    fontSize: '2rem',
    textAlign: 'right',
    color: colors.yellow,
    flex: '1', // Reserve space for the text
  };


  const textStyle = {
    fontFamily: 'LondrinaSolid',
    fontSize: '2rem',
    textAlign: 'right',
    flex: '1', // Reserve space for the text
  };

  const timelineStyle = {
    flex: '3', // Make the timeline take more space
  };

  return (
    <div style={{ width: '100%', padding: '20px' }}>
      {/* Row for Education */}
      <div style={rowStyle}>
        <div style={educTextStyle}>Education</div>
        <Timeline style={timelineStyle}>
          <TimelineItem>
            <TimelineOppositeContent style={{ fontFamily: 'LondrinaSolid' }}>
              aug 2021 - may 2025
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ fontFamily: 'LondrinaSolid' }}>
              GEORGIA INSTITUTE OF TECHNOLOGY, COLLEGE OF COMPUTING
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent style={{ fontFamily: 'LondrinaSolid' }}>
              aug 2017 - may 2021
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
            </TimelineSeparator>
            <TimelineContent style={{ fontFamily: 'LondrinaSolid' }}>
              WESLEYAN SCHOOL
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>

      {/* Row for Experience */}
      <div style={rowStyle}>
        <div style={experienceTextStyle}>Experience</div>
        <Timeline style={timelineStyle}>
          <TimelineItem>
            <TimelineOppositeContent style={{ fontFamily: 'LondrinaSolid' }}>
              September 2024 - November 2024
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ fontFamily: 'LondrinaSolid' }}>
              SOFTWARE ENGINEERING INTERN @ AMAZON
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent style={{ fontFamily: 'LondrinaSolid' }}>
              June 2024 - August 2024
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ fontFamily: 'LondrinaSolid' }}>
              SOFTWARE ENGINEERING INTERN @ MICROSOFT
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent style={{ fontFamily: 'LondrinaSolid' }}>
              January 2024 - May 2024
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ fontFamily: 'LondrinaSolid' }}>
              SOFTWARE ENGINEERING INTERN @ GTRI
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent style={{ fontFamily: 'LondrinaSolid' }}>
              June 2023 - August 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ fontFamily: 'LondrinaSolid' }}>
              ENGINEERING SUMMER ANALYST @ GOLDMAN SACHS
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent style={{ fontFamily: 'LondrinaSolid' }}>
              January 2023 - May 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ fontFamily: 'LondrinaSolid' }}>
              SOFTWARE ENGINEERING INTERN @ GTRI
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent style={{ fontFamily: 'LondrinaSolid' }}>
              May 2022 - August 2022
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
            </TimelineSeparator>
            <TimelineContent style={{ fontFamily: 'LondrinaSolid' }}>
              SOFTWARE ENGINEERING INTERN @ HOTEL EFFECTIVENESS
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    </div>
  );
};

export default Resume;
